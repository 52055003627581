.app-footer {
    background-color: #1E2329;
    color: #fff;
    padding: 14px;
    text-align: center;
}
.social-links {
    display: flex;
    justify-content: center; /* Centra los elementos horizontalmente */
    align-items: center; /* Centra los elementos verticalmente */
    /* Otros estilos para el contenedor de enlaces sociales */
  }

  .social-links img{
    width: 30px; /* Ajusta este valor según sea necesario */
    height: auto;
    margin: 0 10px; /* Ajusta el espacio entre los iconos según sea necesario */
  }