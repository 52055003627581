/* Consultation.css */
.consultation-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 82vh;
}

.image-container {
  flex: 1;
}

.form-container {
  flex: 1;
  padding: 40px;
}

.form-image {
  width: 100%;
  height: auto;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.submit-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #0056b3;
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 4px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.alert.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.alert.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}