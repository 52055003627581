.header {
  background-color: #1E2329;
  width: 100%;
  color: #fff;
  display: flex;
  justify-content: space-between;
  justify-content: right;
  align-items: center;
  position: fixed;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  height: 15vh;
}


.header nav {
  text-align: center;
}

.header ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.header li {
  display: inline-block;
  margin-right: 20px;
}

.header a {
  font-family: "Gotham book, Sans-serif";
  font-size: 20px;
  padding: 0px 10px 0px 10px;
  color: #FFFFFF;
  text-decoration: none;
}

.header a:hover {
  color: #91908c;
}

.hamburger-icon {
  display: none;
  color: white;
  background-color: transparent;
}

.logo-container {
  margin-right: auto;
  /* Alinea el logo a la izquierda */
  height: 15vh;
}

.logo {
  height: 15vh;
}

@media only screen and (max-width: 1024px) {
  .logo-container {
    width: 100vw;
    background-color: #fff;
    height: 10vh;

  }

  .logo {
    height: 10vh;
  }

  .header {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  }

  .hamburger-icon {
    cursor: pointer;
    padding: 10px;
    display: block;
    /* Mostrar el icono solo en pantallas pequeñas */
    margin-left: auto;
    /* Mover el icono a la derecha */
  }

  .nav {
    display: none;
    width: 100%;
    background-color: transparent;
  }

  .nav.open {
    display: block;
  }

  .nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .nav li {
    margin-bottom: 10px;
    text-align: center;
  }

  .nav a {
    font-family: "Gotham book, Sans-serif";
    font-size: 20px;
    padding: 10px;
    color: #FFFFFF;
    text-decoration: none;
    display: block;
  }

  .nav a:hover {
    color: #91908c;
  }

  .header li {
    display: block;
  }
}

.phone-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
}

.rounded-icon {
  background-color: #fff;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Esto centrará el ícono dentro del círculo */
}

/* Ajusta el tamaño del ícono según sea necesario */
.phone-icon img {
  width: 20px;
  height: 20px;
  /* Asegura que el ícono sea cuadrado */
}

.phone-number-text {
  padding-left: 10px;
  font-size: 20px;
}

.nav {
  position: relative; /* Para permitir que el submenu sea relativo a este elemento */
}

.submenu {
  display: none; /* Ocultar el submenu por defecto */
  position: absolute; /* Establecer la posición absoluta */
  top: 100%; /* Posicionar debajo del elemento padre */
  left: 0; /* Alinear con el lado izquierdo del elemento padre */
  width: 200px; /* Ancho del submenu */
  background-color: #fff; /* Color de fondo del submenu */
  padding: 10px; /* Espaciado interno */
  border: 1px solid #ccc; /* Borde para visualización */
}

/* Mostrar el submenu cuando el estado isSubmenuOpen sea verdadero */
.submenu.open {
  display: block;
}