.home-container {
  margin: 0 auto;
}

body {
  overflow-x: hidden;
}

.home-intro {
  margin-left: 4vw;
  margin-right: 4vw;
  margin-bottom: 20px;
  padding-top: 50pt;
  padding-bottom: 30pt;
}

.justice-img {
  width: 100%;
  height: 100%;
}


.home-title {
  font-size: 2em;
  color: #000;
  margin-bottom: 0.5em;
  font-family: "Gotham book, Sans-serif";
  text-align: center;
  padding-bottom: 30pt;
}

.home-par {
  font-size: 1.2em;
  color: #333;
  font-family: "Gotham book, Sans-serif";
  margin: 5px;
}

.main-image-container {
  max-width: 100%;
  margin: 0 auto;
}

.main-image-container img {
  width: 100%;
  height: auto;
  display: block;
}

.top-section {
  position: relative;
  background-color: #000;
  padding: 20px;
  text-align: center;
  width: 100%;
  color: #fff;
  transition: background-color 0.3s ease;
}

.top-section h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  /* Color del texto blanco */
  margin: 0;
  font-size: 4em;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);

}


.areas-section {
  padding: 20px;
  text-align: center;
  padding-top: 70pt;

}

.areas-section h2 {
  color: #000;
  /* Color del texto negro */
}


.practice-area h3 {
  color: #000
}

.practice-area ul {
  list-style: none;
  padding: 0;
}

.practice-area li {
  margin-bottom: 10px;
}

.mission-values-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: justify;
  margin: 0 auto;
  padding-top: 80pt;
  padding-bottom: 50pt;

}

.mission-values-text-container {
  padding-left: 4vh;
  padding-right: 4vh;
}

.profile-photo-container {
  width: 100%;
}

.profile-photo-container img {
  width: 100%;
  height: auto;
  display: block;
}

.alianza-description {
  line-height: 2;
}

.alianza-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: justify;
  margin: 0 auto;
  width: 95%;
  padding-top: 80pt;
  padding-bottom: 50pt;
}

.alianza-section p {
  line-height: 1.6;
}

.mission-values {
  align-items: center;
}

.mission-values ul {
  list-style-type: disc;
  padding-left: 20px;
}

.image-container {
  display: flex;
}

.container {
  background: rgb(240, 242, 245);
  width: 100vw;
  display: flex;
  justify-content: center;
  padding-top: 8vh;
}

.content {
  width: 80vw;
  background: rgb(252, 248, 245);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.practice-areas {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  /* Agregamos un espacio entre las áreas */
}

.practice-area {
  width: 30%;
  /* Ancho de cada área de práctica */
  text-align: left;
  margin-bottom: 20px;
  /* Añadimos espacio debajo de cada área */
  display: flex;
  flex-direction: column;
  /* Establecemos una disposición de columna para los elementos dentro de cada área */
  align-items: center;
  /* Centramos horizontalmente el contenido dentro de cada área */
}

.area-icon-container {
  width: 60px;
  /* Ajustamos el ancho del contenedor del icono */
  height: 60px;
  display: flex;
  align-items: center;
  /* Centramos verticalmente el icono */
  justify-content: center;
  /* Centramos horizontalmente el icono */
  border-radius: 50%;
  /* Aseguramos que el contenedor del icono sea circular */
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  /* Agregamos una sombra al contenedor del icono */
  overflow: hidden;
  /* Evitamos que el icono se desborde del contenedor */
}

.area-icon {
  width: 100%;
  /* Ajustamos el tamaño del icono para que llene su contenedor */
  height: auto;
}

.area-description {
  width: 100%;
  /* Ajustamos el ancho del área de descripción */
}

@media (max-width: 768px) {
  .content {
    width: 100vw;
  }

  .practice-area {
    width: 100%;
  }

  .area-description {
    width: 100%;
  }
}

.image-container {
  position: relative;
  display: inline-block;
}

.image-container img {
  width: 100%;
  height: auto;
  display: block;
  filter: brightness(70%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
}

.image-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  font-size: 2.5em;
}

.image-text button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 25px;
  border-radius: 5px;
  font-size: 25px;
  cursor: pointer;
}

.image-text button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .image-text {
    top: 40vh;
    font-size: 2em;
  }

  .image-text button {
    font-size: 22px;
    margin-top: 22px;
  }

  .main-image-container img {
    padding-top: 10vh;
  }

  .areas-section, .mission-values-section, .home-intro {
    padding-top: 120pt;
  }
}

@media (max-width: 420px) {
  .image-text {
    top: 34vh;
    font-size: 1.2em;
  }

  .image-text button {
    font-size: 17px;
    margin-top: 17px;
  }

  .main-image-container img {
    padding-top: 10vh;
  }
}