.whatsapp-banner {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25D366; /* Color de fondo de WhatsApp */
    padding: 10px;
    border-radius: 50%; /* Hace que el banner sea un círculo */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;

  }
  
  .whatsapp-banner img {
    width: 40px;
    height: auto;
    font-weight: 900;
    
  }
  
  .whatsapp-banner:hover {
    background-color: #128C7E; /* Cambia el color de fondo al pasar el ratón por encima */
  }
  