/* components/Contact.css */

.contact-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    padding-top: 60pt;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  
  .contact-form label {
    margin-bottom: 10px;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    box-sizing: border-box; /* Añade esta línea para ajustar el box-sizing */
    border: 1px solid #ccc; /* Añade un borde para visualizar los límites del formulario */
  }
  
  .contact-form button {
    background-color: #4caf50;
    color: white;
    padding: 10px;
    cursor: pointer;
    width: 100%;
  }
  
  .contact-form button:hover {
    background-color: #45a049;
  }
  
  .contact-info,
  .social-media {
    margin-top: 20px;
  }
  
  .response-time,
  .thank-you {
    margin-top: 20px;
  }
  