.carousel {
  position: relative;
  overflow: hidden;
  width: 100%;
  z-index: 0; /* Base del carrusel */
}

.carousel-image-container {
  width: 100%;
  height: 100%;
}

.carousel-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Estilo de los puntos debajo del carrusel */
.carousel-dots {
  position: absolute;
  bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  height: 12px;
  width: 12px;
  margin: 0 6px;
  background-color: rgba(255, 255, 255, 0.7); /* Fondo más suave */
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.4s ease, transform 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.2); /* Borde elegante */
}

.dot.active {
  background-color: #333; /* Fondo oscuro para el punto activo */
  border-color: #fff; /* Borde blanco para destacar */
  transform: scale(1.2); /* Efecto de aumento */
}

/* Flechas de navegación */
.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10; /* Asegura que esté encima de otros elementos */
  transition: background-color 0.3s ease;
}

.carousel-arrow:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.left-arrow {
  left: 15px;
  font-size: 18px;
}

.right-arrow {
  right: 15px;
  font-size: 18px;
}

/* Responsivo */
@media (max-width: 1020px) {
  .carousel {
    height: 80vh;
  }
}

